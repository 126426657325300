import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

interface AuthMiddlewareProps {
  children: React.ReactNode;
}

export const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  const { user } = useAuth();
  console.log("user auth", user)
  const location = useLocation();
  const isAuthenticated = user?.access !== "" && user?.refresh !== "" && user !== null;
  const isPrivateRoute = location.pathname.startsWith('/home');
  const isPublicRoute = !isPrivateRoute;

  // If user is not authenticated and trying to access any route other than public routes
  if (!isAuthenticated && isPrivateRoute) {
    return <Navigate to="/" replace />;
  }

  // If user is authenticated and trying to access public routes
  if (isAuthenticated && isPublicRoute) {
    return <Navigate to="/home/transfer" replace />;
  }

  return <>{children}</>;
};
